import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/hale-sd/hale-white.png'


const _withScopeId = n => (_pushScopeId("data-v-38faaef7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "hale-ui-app-bar-nav",
  class: "app-bar-ui__nav d-none justify-center d-sm-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_localeSwitcherComponent = _resolveComponent("localeSwitcherComponent")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_navbarDrawerComponent = _resolveComponent("navbarDrawerComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app_bar, {
      class: "app-bar-ui__bar",
      app: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNavigationDrawer())),
          class: "d-flex d-sm-none"
        }),
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_img, {
              class: "mx-2",
              src: _imports_0,
              width: "90",
              height: "90",
              "max-height": "90",
              "max-width": "90",
              contain: ""
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            class: "app-bar-ui__nav-items",
            exactActiveClass: "app-bar-ui__selected-nav-item",
            to: "/home"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("HOME")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "app-bar-ui__nav-items",
            exactActiveClass: "app-bar-ui__selected-nav-item",
            to: "/projects"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("PROJECTS")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            class: "app-bar-ui__nav-items",
            exactActiveClass: "app-bar-ui__selected-nav-item",
            to: "/contact"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("CONTACT_US")), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_localeSwitcherComponent)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_navbarDrawerComponent, {
      ref: "navbarDrawer",
      style: {"height":"100%"}
    }, null, 512)
  ], 64))
}