<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <div class="projects-component__container" v-if="projects.length > 0 && ($vuetify.display.mobile && !$vuetify.display.mdAndUp)">
        <portfolioProjectMobileComponent v-for="project in projects" :key="project.id" :projectDetails="project"></portfolioProjectMobileComponent>
    </div>

    <div class="projects-component__container" v-else-if="projects.length > 0">
        <portfolioProjectComponent v-for="project in projects" :key="project.id" :projectDetails="project"></portfolioProjectComponent>
    </div>

    <div class="projects-component__container" v-else>
        <haleUiEmptyState></haleUiEmptyState>
    </div>
</template>

<script lang="ts" src="./projects-component.ts"></script>
<style scoped lang="scss" src="./projects-component.scss"></style>