<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-card class="portfolio-project-mobile__card mx-auto elevation-1" v-on:click="handleRequestDemo(projectDetails?.title)">
        <div class="d-flex flex-no-wrap justify-space-between">
            <v-avatar
                class="ma-3"
                rounded="0"
                size="125">
                <v-img
                    class="portfolio-project-mobile__project-image"
                    aspect-ratio="1/1"
                    style="height: 100%; width: 100%"
                    :src="getImagePath(projectDetails?.image_path)"            
                ></v-img>
            </v-avatar>    
            <div>
                <v-card-title 
                    class="pa-2 text-subtitle-1 portfolio-project-mobile__text portfolio-project-mobile__title">
                    {{  projectDetails.title  }}
                </v-card-title>

                <v-card-text class="pa-2 text-body-2 portfolio-project-mobile__text  portfolio-project-mobile__description">
                    {{  projectDetails?.description  }}
                </v-card-text>
            </div>
        </div>
    </v-card>
</template>

<script lang="ts" src="./portfolio-project-mobile-component.ts"></script>
<style scoped lang="scss" src="./portfolio-project-mobile-component.scss"></style>
