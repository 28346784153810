<!-- Copyright 2024 Hale Software Development.  -->
<template>
    <v-navigation-drawer
        v-model="showDrawer"
        absolute
        temporary>

        <v-list nav dense> 
            <v-list-item 
                v-for="item in items" 
                :to="item.link" 
                :key="item.value" 
                :title="item.title" 
                >
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script lang="ts" src="@/views/hale-ui/shared/components/hale-ui-app-bar/components/nav-bar-drawer/nav-bar-drawer-component.ts"></script>