import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f02722e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "portfolio-project__features-list-container" }
const _hoisted_2 = { class: "portfolio-project__text portfolio-project__features-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_divider = _resolveComponent("v-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_card, { class: "portfolio-project__card mx-auto elevation-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "fill-height" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "6",
              sm: "6"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_img, {
                  class: "portfolio-project__project-image",
                  "aspect-ratio": "4/3",
                  cover: "",
                  style: {"height":"80%","width":"100%"},
                  src: _ctx.getImagePath(_ctx.projectDetails?.image_path)
                }, null, 8, ["src"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "6",
              sm: "6"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_v_card_title, { class: "pa-2 text-subtitle-1 text-lg-h3 text-md-h4 portfolio-project__text portfolio-project__title" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.projectDetails.title), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_card_text, { class: "pa-2 portfolio-project__text portfolio-project__description" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.projectDetails?.description), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, { cols: "8" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("ul", _hoisted_2, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectDetails?.feature_list, (feature) => {
                                return (_openBlock(), _createElementBlock("li", { key: feature }, _toDisplayString(feature), 1))
                              }), 128))
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { cols: "4" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_card_actions, { class: "portfolio-project__card-actions" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_btn, {
                                class: "portfolio-project__request-demo-btn text-none",
                                variant: "flat",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRequestDemo(_ctx.projectDetails?.title)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t("REQUEST_DEMO")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_divider, {
      class: "border-opacity-40",
      thickness: "2",
      color: "white"
    })
  ], 64))
}