<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-card class="portfolio-project__card mx-auto elevation-0">
        <v-row class="fill-height">
            <v-col cols="6" sm="6">
                <v-img
                    class="portfolio-project__project-image"
                    aspect-ratio="4/3"
                    cover
                    style="height: 80%; width: 100%"
                    :src="getImagePath(projectDetails?.image_path)"        
                >
                </v-img>
            </v-col>
            <v-col cols="6" sm="6">
                <div>
                    <v-card-title 
                        class="pa-2 text-subtitle-1 text-lg-h3 text-md-h4 portfolio-project__text portfolio-project__title">
                        {{  projectDetails.title  }}
                    </v-card-title>
        
                    <v-card-text class="pa-2 portfolio-project__text  portfolio-project__description">
                        {{  projectDetails?.description  }}
                    </v-card-text>

                    <v-row>
                        <v-col cols="8">
                            <div class="portfolio-project__features-list-container">
                                <ul class="portfolio-project__text portfolio-project__features-list">
                                     <li v-for="feature in projectDetails?.feature_list" :key="feature">
                                         {{ feature }}
                                     </li>
                                </ul>
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <v-card-actions class="portfolio-project__card-actions">
                                <v-btn class="portfolio-project__request-demo-btn text-none" 
                                    variant="flat"
                                    v-on:click="handleRequestDemo(projectDetails?.title)">
                                        {{ $t("REQUEST_DEMO") }}
                                </v-btn>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-card>
    <v-divider class="border-opacity-40" thickness="2" color="white"></v-divider>
</template>

<style scoped lang="scss" src="./portfolio-project.scss"></style>
<script lang="ts" src="./portfolio-project.ts"></script>
