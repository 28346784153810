<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <div>
        <div v-if="$vuetify.display.mobile && !$vuetify.display.mdAndUp">
            <homeMobileComponent></homeMobileComponent>
        </div>
        <div v-else>
            <v-row class="home-component__messages-view">
                <v-col cols="6" class="home-component__description-container">
                    <section class="home-component__description-title-section">
                        <span v-resize-text="{ratio:1.1, maxFontSize: '60px', minFontSize: '30px'}" 
                                class="home-component__description-title typewriter no-caret">
                            {{ $t("HOME_DESCRIPTION_TITLE") }}
                        </span>
                    </section>
    
                </v-col>
                <v-col cols="6" class="home-component__description-container">
                    <span v-resize-text="{ratio:1.8, maxFontSize: '36px', minFontSize: '20px'}" class="home-component__description-text">
                        {{ $t("HOME_DESCRIPTION_PROBLEMS") }}
                    </span>
                </v-col>            
            </v-row>
            <v-row class="home-component__deliverables-container">
                <div class="home-component__deliverable-container">
                    <v-col cols="12" class="home-component__deliverables">
                        <span class="home-component__deliverable-title">
                            {{ $t("WHAT_WE_DELIVER") }}
                        </span>
                    </v-col>
                    <div>
                        <v-row no-gutters>
                            <v-col>
                                <v-img
                                    :width="225"
                                    aspect-ratio="4/3"
                                    cover
                                    src="../../../assets/images/hale-sd/software-development.png"
                                    class="home-component__deliverable-image">
                                </v-img>
                                <span v-resize-text="{ratio:1.5, maxFontSize: '36px', minFontSize: '20px'}" class="home-component__deliverable-items">
                                    {{ $t("WEB_DEVELOPMENT") }}
                                </span>
                            </v-col>
                            <v-col>
                                <v-img
                                    :width="225"
                                    aspect-ratio="4/3"
                                    cover
                                    src="../../../assets/images/hale-sd/integrations-white.png"
                                    class="home-component__deliverable-image">
                                </v-img>
                                <span v-resize-text="{ratio:1.5, maxFontSize: '36px', minFontSize: '20px'}" class="home-component__deliverable-items">
                                    {{ $t("INTEGRATIONS") }}
                                </span>
                            </v-col>
                            <v-col>
                                <v-img
                                    :width="225"
                                    aspect-ratio="4/3"
                                    cover
                                    src="../../../assets/images/hale-sd/ux-white.png"
                                    class="home-component__deliverable-image">
                                </v-img>
                                <span v-resize-text="{ratio:1.5, maxFontSize: '36px', minFontSize: '20px'}" class="home-component__deliverable-items">
                                    {{ $t("UI_UX_DESIGNS") }}
                                </span>
                            </v-col>
                        </v-row>
                    </div>              
                </div>
            </v-row>
        </div>
    </div>
</template>

<script lang="ts" src="./home-component.ts"></script>
<style scoped lang="scss" src="./home-component.scss"></style>