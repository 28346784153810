/**
  © Copyright 2024 Hale Software Development.  
*/

import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: 'HaleUILoader',
    props: {
        isOpen: { type: Boolean as PropType<Boolean>, required: true }
    },
    data() {
        return {
            loaderTitle: this.$t("UPLOADING_INFORMATION")
        }
    },
    
})