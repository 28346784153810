/**
  ©Copyright 2024 Hale Software Development.  
*/

import { defineComponent, ref } from 'vue'
import { useMonitorSize } from '@/views/hale-ui/shared/util/responsive-handler'
import navbarDrawerComponent from '@/views/hale-ui/shared/components/hale-ui-app-bar/components/nav-bar-drawer/nav-bar-drawer-component.vue'
import localeSwitcherComponent from './components/locale-switcher-component/locale-switcher-component.vue'

export default defineComponent({
    name: 'HaleUiAppBarComponent',
    components: {
      navbarDrawerComponent,
      localeSwitcherComponent
    },
    data() {
      return {
        sizes: useMonitorSize(),
      }
    },
    methods: {
      showNavigationDrawer(): void {
        (this.$refs['navbarDrawer'] as any).toggleDrawer();
      }
    }
})