import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-103efcaf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "projects-component__container"
}
const _hoisted_2 = {
  key: 1,
  class: "projects-component__container"
}
const _hoisted_3 = {
  key: 2,
  class: "projects-component__container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portfolioProjectMobileComponent = _resolveComponent("portfolioProjectMobileComponent")!
  const _component_portfolioProjectComponent = _resolveComponent("portfolioProjectComponent")!
  const _component_haleUiEmptyState = _resolveComponent("haleUiEmptyState")!

  return (_ctx.projects.length > 0 && (_ctx.$vuetify.display.mobile && !_ctx.$vuetify.display.mdAndUp))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
          return (_openBlock(), _createBlock(_component_portfolioProjectMobileComponent, {
            key: project.id,
            projectDetails: project
          }, null, 8, ["projectDetails"]))
        }), 128))
      ]))
    : (_ctx.projects.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
            return (_openBlock(), _createBlock(_component_portfolioProjectComponent, {
              key: project.id,
              projectDetails: project
            }, null, 8, ["projectDetails"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_haleUiEmptyState)
        ]))
}