/**
  Copyright 2024 Hale Software Development.  
*/

import { defineComponent, PropType } from "vue";
import contactFormComponent  from './shared/contact-form-component/contact-form-component.vue'
import footerInformationComponent from './shared/footer-information-component/footer-information-component.vue'
import footerInformationMobileComponent from "./shared/footer-information-mobile-component/footer-information-mobile-component.vue";
import { useRoute }  from 'vue-router';

export default defineComponent({
  name: 'ContactUsComponent',
  components: {
    contactFormComponent,
    footerInformationComponent,
    footerInformationMobileComponent
  },
  data() {
    return {}
  }
})