/**
  © Copyright 2024 Hale Software Development.  
*/

export enum NotificationType {
    SUCCESS, 
    WARNING, 
    ERROR,
    UNSET
}

