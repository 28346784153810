<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <div class="center">
        <v-dialog
            v-model="isOpen"
            max-width="320"
            persistent>

            <v-list
                class="py-2"
                color="primary"
                elevation="12"
                rounded="lg">

                <v-list-item :title="loaderTitle">
                    <template v-slot:append>
                        <v-progress-circular
                        color="primary"
                        indeterminate="disable-shrink"
                        size="16"
                        width="2"
                        ></v-progress-circular>
                    </template>
                </v-list-item>
            </v-list>
        </v-dialog>
    </div>
</template>

<script lang="ts" src="./hale-ui-loader.ts"></script>