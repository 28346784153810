import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/images/hale-sd/software-development.png'
import _imports_1 from '@/assets/images/hale-sd/integrations-white.png'
import _imports_2 from '@/assets/images/hale-sd/ux-white.png'


const _withScopeId = n => (_pushScopeId("data-v-c1137056"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-mobile-component__description-title-section" }
const _hoisted_2 = { class: "home-mobile-component__description-title typewriter no-caret" }
const _hoisted_3 = { class: "home-mobile-component__description-text" }
const _hoisted_4 = { class: "home-mobile-component__deliverable-container" }
const _hoisted_5 = { class: "home-mobile-component__deliverable-title" }
const _hoisted_6 = { class: "home-mobile-component__deliverables" }
const _hoisted_7 = { class: "home-mobile-component__deliverable-items" }
const _hoisted_8 = { class: "home-mobile-component__deliverable-items" }
const _hoisted_9 = { class: "home-mobile-component__deliverable-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _directive_resize_text = _resolveDirective("resize-text")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_row, { class: "home-mobile-component__messages-view" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { class: "home-mobile-component__description-container" }, {
          default: _withCtx(() => [
            _createElementVNode("section", _hoisted_1, [
              _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.$t("HOME_DESCRIPTION_TITLE")), 1)
              ])), [
                [_directive_resize_text, {ratio:0.3, maxFontSize: '34px', minFontSize: '32px'}]
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, { class: "home-mobile-component__messages-view" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { class: "home-mobile-component__description-container" }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.$t("HOME_DESCRIPTION_PROBLEMS")), 1)
            ])), [
              [_directive_resize_text, {ratio:0.7, maxFontSize: '16px', minFontSize: '16px'}]
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_v_col, { class: "home-mobile-component__deliverables" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("WHAT_WE_DELIVER")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      width: 105,
                      "aspect-ratio": "4/3",
                      cover: "",
                      src: _imports_0,
                      class: "home-mobile-component__deliverable-image"
                    }),
                    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_7, [
                      _createTextVNode(_toDisplayString(_ctx.$t("WEB_DEVELOPMENT")), 1)
                    ])), [
                      [_directive_resize_text, {ratio:1.5, maxFontSize: '28px', minFontSize: '14px'}]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      width: 105,
                      "aspect-ratio": "4/3",
                      cover: "",
                      src: _imports_1,
                      class: "home-mobile-component__deliverable-image"
                    }),
                    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_8, [
                      _createTextVNode(_toDisplayString(_ctx.$t("INTEGRATIONS")), 1)
                    ])), [
                      [_directive_resize_text, {ratio:1.5, maxFontSize: '28px', minFontSize: '14px'}]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { "no-gutters": "" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_img, {
                      width: 105,
                      "aspect-ratio": "4/3",
                      cover: "",
                      src: _imports_2,
                      class: "home-mobile-component__deliverable-image"
                    }),
                    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_9, [
                      _createTextVNode(_toDisplayString(_ctx.$t("UI_UX_DESIGNS")), 1)
                    ])), [
                      [_directive_resize_text, {ratio:1.5, maxFontSize: '28px', minFontSize: '14px'}]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ], 64))
}