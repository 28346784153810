/**
  ©Copyright 2024 Hale Software Development.  
*/

import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { createI18n } from 'vue-i18n'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import i8ln from './assets/messages/translations'

// JS only modules that use require for TS
const VueResizeText = require("vue3-resize-text")

loadFonts()

// Change the title of the page whenever a new route is detected or use the default one
router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string || i8ln.global.t(`HALE_SD`);
  next();
});

//Stop error resizeObserver
const debounce = (callback: (...args: any[]) => void, delay: number) => {
  let tid: any;
  return function (...args: any[]) {
    const ctx = self;
    tid && clearTimeout(tid);
    tid = setTimeout(() => {
      callback.apply(ctx, args);
    }, delay);
  };
};
    
const _ = (window as any).ResizeObserver;
(window as any). ResizeObserver = class ResizeObserver extends _ {
  constructor(callback: (...args: any[]) => void) {
    callback = debounce (callback, 20);
    super(callback);
  }
};

createApp(App).use(router)
  .use(i8ln)
  .use(vuetify)
  .use(VueResizeText)
  .mount('#app')
