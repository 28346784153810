/**
  ©Copyright 2024 Hale Software Development.  
*/

import { Project } from '@/api/projects/projects.model'
import { projectsAPI } from '@/api/projects/projects.service'
import { defineComponent, isProxy, toRaw } from 'vue'
import haleUiEmptyState from '@/views/hale-ui/shared/components/hale-ui-empty-state/hale-ui-empty-state.vue';
import portfolioProjectComponent from './shared/components/portfolio-project/portfolio-project.vue'
import portfolioProjectMobileComponent from './shared/components/portfolio-project-mobile-component/portfolio-project-mobile-component.vue';


export default defineComponent({
  name: 'ProjectsComponent',
  components: {
    portfolioProjectComponent,
    portfolioProjectMobileComponent,
    haleUiEmptyState
  },
  data() {
    return {
        projects: [] as Project[]
    }
  },
  methods: {
    async getAllProjects(): Promise<void> {
        this.projects = await projectsAPI.getProjects();    
    }
  },
  async mounted() {
    await this.getAllProjects(); 
  }
})