/**
  ©Copyright 2024 Hale Software Development.  
*/

export interface ApiResponse {
  response_key: ResponseMessage;
  response_message: string;
  data: any;   
}

export enum ResponseMessage {
  SUCCESS = "SUCCESS",
  DATA_NOT_FOUND = "DATA_NOT_FOUND", 
  UNKNOWN_ERROR = "UNKNOWN_ERROR", 
  INVALID_REQUEST = "INVALID_REQUEST", 
  UNAUTHORIZED = "UNAUTHORIZED"
}