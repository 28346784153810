/**
  ©Copyright 2024 Hale Software Development.  
*/

import { defineComponent } from "vue";

export default defineComponent({
    name: 'NavbarDrawerComponent',
    data() {
        return {
            items: [ 
                {
                    title: this.$t('HOME'),
                    value: 'home',
                    link: "/home"
                },
                {
                    title: this.$t('PROJECTS'),
                    value: 'projects',
                    link: "/projects"
                },
                {
                    title: this.$t('CONTACT_US'),
                    value: 'contact',
                    link: "/contact"
                },
            ],
            showDrawer: false
        }
    },
    methods: {
        toggleDrawer(): void {
            this.showDrawer = !this.showDrawer;
        }   
    }
})