/**
  ©Copyright 2024 Hale Software Development.  
*/

import axios, { AxiosInstance } from "axios";
import { DemoRequest } from "./demo-request.model";
import { ApiResponse } from "../shared/models/api-response";

export default class DemoRequestService {
    private axiosInstance: AxiosInstance;
    private readonly baseUrl = "https://9250ixv6g4.execute-api.us-east-1.amazonaws.com/prod/beta/demorequest";

    constructor() {
        this.axiosInstance = axios.create({
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
                "Content-Type": "application/json"
            }
        });
    }

    /**
     * API call to add demo request obtained from Contact Us page
     */
    async addDemoRequest(demoRequest: DemoRequest) {
        return await axios.post<ApiResponse>(this.baseUrl, demoRequest)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error; 
            });
    }
}

export const DemoRequestApi = new DemoRequestService();