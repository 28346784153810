/**
  ©Copyright 2024 Hale Software Development.  
*/

import { Project } from "@/api/projects/projects.model";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    name: 'PortfolioProjectMobileComponent',
    props: {
        projectDetails: { type: Object as PropType<Project>, required: true }
    },
    methods: {
        /** After on click event, redirect to Contact Us Page */
        handleRequestDemo(projectTitle: string): void {
            this.$router.push({ name: 'contact', query: { title: projectTitle } })
        },
        getImagePath(path: string): string {
          return path ? require("@/assets/images/" + path) : require("@/assets/images/hale-sd/coming-soon.jpg")
        }
    },
    data() {
        return {}
    }
})