import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e1437f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer-information-component-mobile__item text-center text-caption" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_openBlock(), _createBlock(_component_v_footer, {
    class: "bg-white justify-center",
    absolute: "",
    inset: "",
    app: "",
    width: "auto"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_v_icon, { icon: "mdi-copyright" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("COPYRIGHT")), 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}