<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-footer class="bg-white justify-center" absolute inset app width="auto">
        <v-row>
            <v-col>
                <span class="footer-information-component-mobile__item text-center text-caption"> 
                    <v-icon icon="mdi-copyright"></v-icon> {{ $t("COPYRIGHT") }}
                </span>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script lang="ts" src="./footer-information-mobile-component.ts"></script>
<style scoped lang="scss" src="./footer-information-mobile-component.scss"></style>