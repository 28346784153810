<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-sheet>
        <v-snackbar
            elevation="24"
            v-model="showNotification"
            :timeout="timeToClose"
            :color="notificationType === 0 ? 'success' : 'error'">

            <span 
                v-if="notificationType === 0">
                {{ $t("THANK_YOU_FOR_CONTACTING_US") }}
            </span>

            <span v-else-if="notificationType === 2">
                {{ $t("AN_ERROR_OCURRED") }}
            </span>
        </v-snackbar>
    </v-sheet>
</template>

<script lang="ts" src="./hale-ui-notification.ts"></script>