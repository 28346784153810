import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_dialog, {
      modelValue: _ctx.isOpen,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isOpen) = $event)),
      "max-width": "320",
      persistent: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, {
          class: "py-2",
          color: "primary",
          elevation: "12",
          rounded: "lg"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, { title: _ctx.loaderTitle }, {
              append: _withCtx(() => [
                _createVNode(_component_v_progress_circular, {
                  color: "primary",
                  indeterminate: "disable-shrink",
                  size: "16",
                  width: "2"
                })
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}