/**
  ©Copyright 2024 Hale Software Development.  
*/

import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: 'LocaleSwitcherComponent',
    data() {
        return {
            languages: [
                { title: this.$t('ENGLISH'), value: 'en' },
                { title: this.$t('SPANISH'), value: 'es' }
            ]
        }
    },
    methods: {
        toggleLanguage(value: string): void {
            this.$i18n.locale = value;
        }
    }
})