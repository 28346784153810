<!-- Copyright 2024 Hale Software Development.  -->
 
<template>
    <v-row class="home-mobile-component__messages-view">
        <v-col class="home-mobile-component__description-container">
            <section class="home-mobile-component__description-title-section">
                <span v-resize-text="{ratio:0.3, maxFontSize: '34px', minFontSize: '32px'}" 
                        class="home-mobile-component__description-title typewriter no-caret">
                    {{ $t("HOME_DESCRIPTION_TITLE") }}
                </span>
            </section>
        </v-col>
    </v-row>
    <v-row class="home-mobile-component__messages-view">
        <v-col class="home-mobile-component__description-container">
            <span v-resize-text="{ratio:0.7, maxFontSize: '16px', minFontSize: '16px'}" 
                class="home-mobile-component__description-text">
                {{ $t("HOME_DESCRIPTION_PROBLEMS") }}
            </span>
        </v-col>   
    </v-row>
    <v-row>
        <div class="home-mobile-component__deliverable-container">
            <v-col class="home-mobile-component__deliverables">
                <span class="home-mobile-component__deliverable-title">
                    {{ $t("WHAT_WE_DELIVER") }}
                </span>
            </v-col>
            <div class="home-mobile-component__deliverables">
                <v-row no-gutters>
                    <v-col>
                        <v-img
                            :width="105"
                            aspect-ratio="4/3"
                            cover
                            src="@/assets/images/hale-sd/software-development.png"
                            class="home-mobile-component__deliverable-image">
                        </v-img>
                        <span v-resize-text="{ratio:1.5, maxFontSize: '28px', minFontSize: '14px'}"  
                            class="home-mobile-component__deliverable-items">
                            {{ $t("WEB_DEVELOPMENT") }}
                        </span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-img
                            :width="105"
                            aspect-ratio="4/3"
                            cover
                            src="@/assets/images/hale-sd/integrations-white.png"
                            class="home-mobile-component__deliverable-image">
                        </v-img>
                        <span v-resize-text="{ratio:1.5, maxFontSize: '28px', minFontSize: '14px'}" 
                            class="home-mobile-component__deliverable-items">
                            {{ $t("INTEGRATIONS") }}
                        </span>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col >
                        <v-img
                            :width="105"
                            aspect-ratio="4/3"
                            cover
                            src="@/assets/images/hale-sd/ux-white.png"
                            class="home-mobile-component__deliverable-image">
                        </v-img>
                        <span v-resize-text="{ratio:1.5, maxFontSize: '28px', minFontSize: '14px'}" 
                            class="home-mobile-component__deliverable-items">
                            {{ $t("UI_UX_DESIGNS") }}
                        </span>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-row>
</template>

<script lang="ts" src="./home-mobile-component.ts"></script>
<style scoped lang="scss" src="./home-mobile-component.scss"></style>