import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_snackbar = _resolveComponent("v-snackbar")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createBlock(_component_v_sheet, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_snackbar, {
        elevation: "24",
        modelValue: _ctx.showNotification,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showNotification) = $event)),
        timeout: _ctx.timeToClose,
        color: _ctx.notificationType === 0 ? 'success' : 'error'
      }, {
        default: _withCtx(() => [
          (_ctx.notificationType === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("THANK_YOU_FOR_CONTACTING_US")), 1))
            : (_ctx.notificationType === 2)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t("AN_ERROR_OCURRED")), 1))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "timeout", "color"])
    ]),
    _: 1
  }))
}