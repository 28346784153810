/**
  ©Copyright 2024 Hale Software Development.  
*/

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { Project } from "./projects.model";
import { ApiResponse, ResponseMessage } from "../shared/models/api-response";
import { isProxy, toRaw } from "vue";

export default class ProjectsService {
    private axiosInstance: AxiosInstance;
    private readonly baseUrl = "https://9250ixv6g4.execute-api.us-east-1.amazonaws.com/prod/beta/projects";

    constructor() {
        this.axiosInstance = axios.create({
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*"
            }
        });
    }

    async getProjects() {
        return await axios.get<ApiResponse>(this.baseUrl)
            .then((response) => {
                return response.data?.data;
            })
            .catch((error) => {
                return [];
            });
    }

    async addProject(project: Project) {
        return await axios.post<ApiResponse>(this.baseUrl, project)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    }
}

export const projectsAPI = new ProjectsService();