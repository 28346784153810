<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-menu>
        <template v-slot:activator="{ props }">
            <v-btn 
                color="white"
                v-bind="props"
                icon="mdi-web">
            </v-btn>
        </template>

        <v-list>
            <v-list-item  v-for="(language, index) in languages"
            :key="index"
            :value="language.value"
            @click="toggleLanguage(language.value)">
                <v-list-item-title>{{ language.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
           
            
    </v-menu>
</template>

<script lang="ts" src="./locale-switcher-component.ts"></script>