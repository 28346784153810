import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, null, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, _mergeProps({ color: "white" }, props, { icon: "mdi-web" }), null, 16)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_list, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language, index) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: index,
              value: language.value,
              onClick: ($event: any) => (_ctx.toggleLanguage(language.value))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(language.title), 1)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["value", "onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}