/**
  © Copyright 2024 Hale Software Development.  
*/

import { DemoRequest } from "@/api/demo-request/demo-request.model";
import { DemoRequestApi } from "@/api/demo-request/demo-request.service";
import { ApiResponse, ResponseMessage } from "@/api/shared/models/api-response";
import { defineComponent, PropType } from "vue";
import { useRoute }  from 'vue-router'

import haleUiLoader from '@/views/hale-ui/shared/components/hale-ui-loader/hale-ui-loader.vue';
import haleUiNotification from "@/views/hale-ui/shared/components/hale-ui-notification/hale-ui-notification.vue";
import { NotificationType } from "@/views/hale-ui/shared/components/hale-ui-notification/hale-ui-notification.model";
import HaleUiNotification from "@/views/hale-ui/shared/components/hale-ui-notification/hale-ui-notification.vue";

export default defineComponent({
    name: 'ContactFormComponent',
    components: {
        haleUiLoader,
        haleUiNotification
    },
    data() {
        return {
            formIsValid: false,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            maxCharacters: 20,
            minCharacters: 3,
            maxPhoneCharacters: 11,
            maxEmailCharacters: 40,
            maxMessageCharacters: 300,
            message: '',
            nameRules: [
                (v: any) => (!!v && v.length > 0) || this.$t('NAME_IS_REQUIRED'),
                (v: string | any) => (v && v.length < this.maxCharacters) || 
                    this.$t('NAME_CHARACTER_VALIDATION', { maxCharacters: this.maxCharacters}),
                (v: string | any) => (v && v.length > this.minCharacters) || 
                    this.$t('NAME_MINIMUM_CHARACTER_VALIDATION', { minCharacters: this.minCharacters}),
            ],
            lastNameRules: [
                (v: any) => (!!v && v.length > 0) || this.$t('LAST_NAME_IS_REQUIRED'),
                (v: string | any) => 
                    (v && v.length < this.maxCharacters) || 
                        this.$t('LAST_NAME_CHARACTER_VALIDATION', { maxCharacters: this.maxCharacters}),
                    (v: string | any) => (v && v.length > this.minCharacters) || 
                        this.$t('LAST_NAME_MINIMUM_CHARACTER_VALIDATION', { minCharacters: this.minCharacters}),
            ],
            emailRules: [
                (v: any) => (!!v && v.length > 0) || this.$t('EMAIL_IS_REQUIRED'),
                (v: string | any) => 
                    (v && v.length < this.maxEmailCharacters) || 
                        this.$t('EMAIL_CHARACTER_VALIDATION', { maxCharacters: this.maxEmailCharacters}),
                (v: any) => /^[^\s@]+@[^\s@]+$/.test(v) || 
                        this.$t('EMAIL_MUST_BE_VALID', )
            ],
            phoneRules: [
                (v: any) => (!!v && v.length > 0) || this.$t('PHONE_NUMBER_IS_REQUIRED'),
                (v: any) => (v && v.length <= 10) || this.$t('PHONE_CANT_HAVE_MORE_THAN_TEN_NUMBERS' ),
                (v: any) => Number.isInteger(Number(v)) || this.$t('PHONE_MUST_CONTAIN_ONLY_NUMBERS')
            ],
            isLoading: false,
            isSendDisabled: false,
        }
    },
    mounted() {
        // Get value of project title and add it to the message
        const route = useRoute();
        this.formIsValid = false;
        const projectTitle = route.query.title?.toString();
        if (projectTitle && projectTitle.length > 0) {
            this.message = this.$t('EMAIL_PROJECT_SUBJECT', { projectTitle: projectTitle} )
        }
    },
    methods: {
        /*
         Handle form submit and call validation
        */
        handleFormSubmit(): void {
            this.toggleButtonStates(true);
            (this.$refs['contactform'] as any).validate();
            if (this.formIsValid == null || !this.formIsValid) {
                this.toggleButtonStates(false);
                return;
            }

            const demoRequestForm = {
                first_name: this.firstName,
                last_name: this.lastName, 
                email: this.email,
                phone: this.phone,
                message: this.message 
            } as DemoRequest;
            
            // Call API to upload request form
            DemoRequestApi.addDemoRequest(demoRequestForm)
                .then((response: ApiResponse) => {
                    if (response.response_key == ResponseMessage.SUCCESS) {
                        this.resetForm();
                        this.toggleButtonStates(false);
                        (this.$refs.contactNotification as typeof HaleUiNotification).displayNotification(NotificationType.SUCCESS);
                    }
                    else {
                        // Display error 
                        this.toggleButtonStates(false);
                        (this.$refs.contactNotification as typeof HaleUiNotification).displayNotification(NotificationType.ERROR);
                    }
                }).catch(function (error) {
                    console.error(error);
                });
        },
        /*
         Reset values from the Contact Us form
        */
        resetForm(): void {
            (this.$refs['contactform'] as any).reset();
        },
        /*
         Toggle Send & Loader values
        */
        toggleButtonStates(value: boolean): void {
            this.isLoading = value;
            this.isSendDisabled = value;
        }
    },
    
})