<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-card class="contact-form-component__card">
        <haleUiLoader :isOpen="isLoading"></haleUiLoader>
        <v-form v-model="formIsValid" ref="contactform">
            <v-container>
                <v-row>
                    <v-col cols="6">
                        <span class="contact-form-component__input-title" v-resize-text="{ratio:1.2, maxFontSize: '40px', minFontSize: '22px'}">
                            {{ $t("FIRST_NAME") }}
                        </span>
                        <br>
                        <v-text-field
                            class="custom-form-field"
                            density="compact"
                            variant="outlined"
                            bg-color="white"
                            v-model="firstName"
                            :maxlength="maxCharacters"
                            :rules="nameRules">
                        </v-text-field>
                    </v-col>

                    <v-col cols="6" class="ma-0">
                        <span class="contact-form-component__input-title" v-resize-text="{ratio:1.2, maxFontSize: '40px', minFontSize: '22px'}">
                            {{ $t("LAST_NAME") }}
                        </span>
                        <br>              
                        <v-text-field
                            class="custom-form-field"
                            density="compact"
                            variant="outlined"
                            bg-color="white"
                            v-model="lastName"
                            :maxlength="maxCharacters"
                            :rules="lastNameRules">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <span class="contact-form-component__input-title" v-resize-text="{ratio:1.2, maxFontSize: '40px', minFontSize: '22px'}">
                            {{ $t("EMAIL") }}
                        </span>
                        <br>              
                        <v-text-field
                            class="custom-form-field"
                            density="compact"
                            variant="outlined"
                            bg-color="white"
                            :maxlength="maxEmailCharacters"
                            :rules="emailRules"
                            v-model="email">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <span class="contact-form-component__input-title" v-resize-text="{ratio:1.2, maxFontSize: '40px', minFontSize: '22px'}">
                            {{ $t("PHONE") }}
                        </span>
                        <br>              
                        <v-text-field
                            class="custom-form-field"
                            density="compact"
                            variant="outlined"
                            bg-color="white"
                            :maxlength="maxPhoneCharacters"
                            :rules="phoneRules"
                            v-model="phone">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <span class="contact-form-component__input-title" v-resize-text="{ratio:1.2, maxFontSize: '40px', minFontSize: '22px'}">
                            {{ $t("MESSAGE") }}
                        </span>
                        <br>              
                        <v-textarea
                            class="custom-form-area"
                            :v-bind="message"
                            variant="outlined"
                            bg-color="white"
                            rows="2"
                            v-model="message"
                            :maxlength="maxMessageCharacters"
                            >
                            no-resize>
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-card-actions class="contact-form-component__card-actions">
                    <v-btn
                        right
                        class="contact-form-component__send-btn text-none"
                        color="$color-accent"
                        variant="flat"
                        :disabled="isSendDisabled"
                        @click="handleFormSubmit()">
                        {{ $t("SEND") }}
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-form>
        <haleUiNotification
            ref="contactNotification">
        </haleUiNotification>
    </v-card>
</template>

<script lang="ts" src="./contact-form-component.ts"></script>
<style scoped lang="scss" src="./contact-form-component.scss"></style>