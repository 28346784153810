import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_haleUiAppBar = _resolveComponent("haleUiAppBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_layout = _resolveComponent("v-layout")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_layout, null, {
        default: _withCtx(() => [
          _createVNode(_component_haleUiAppBar),
          _createVNode(_component_v_main, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, {
                fluid: "",
                app: "",
                class: "pa-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view, null, {
                    default: _withCtx(({ Component }) => [
                      _createVNode(_Transition, { name: "fade" }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}