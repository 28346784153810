<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-footer class="bg-white" absolute inset app height="30" width="auto">
        <v-row>
            <v-col cols="4">
                <span class="footer-information-component__item text-body-2" > 
                    <v-icon icon="mdi-copyright"></v-icon> {{ $t("COPYRIGHT") }}
                 </span>
            </v-col>
            <v-col>
                <span class="footer-information-component__item text-body-2">
                    <v-icon icon="mdi-email-outline"></v-icon> {{ $t("HALE_SD_MAIL") }}
                </span>
            </v-col>
            <v-col>
                <span class="footer-information-component__item text-body-2">
                        <v-icon icon="mdi-cellphone"></v-icon> {{ $t("HALE_SD_PHONE") }}
                </span>
            </v-col>
            <v-col>
                <a href="https://www.linkedin.com/in/ren%C3%A9-soto-204064161/" target="_blank" 
                    class="footer-information-component__item text-body-2" 
                    style="text-decoration: none; color: inherit;">
                        <v-icon icon="mdi-linkedin"></v-icon> {{ $t("LINKEDIN") }}
                </a>
            </v-col>
        </v-row>
    </v-footer>
</template>