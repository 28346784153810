/**
  ©Copyright 2024 Hale Software Development.  
*/

import { createI18n } from 'vue-i18n';
import en from './HALE_UI.json'
import es from './HALE_UI_ES.json'

const i8ln = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'es',
    messages: {
      en,
      es
    }
  });

  export default i8ln;