/**
  ©Copyright 2024 Hale Software Development.  
*/

import { defineComponent } from "vue"

export default defineComponent({
    name: 'HaleUiEmptyState',
    data() {
        return {
            description: this.$t("THERE_MIGHT_BE_A_PROBLEM_WITH_OUR_SERVERS"),
            title: this.$t("NOTHING_TO_SEE_HERE")
        }
    }
})