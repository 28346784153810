<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-empty-state
        :text="description"
        :title="title">

        <template v-slot:media>
            <v-img 
                src="@/assets/images/hale-sd/hale-mini-white.png">
            </v-img>
        </template>
    </v-empty-state>
</template>


<script lang="ts" src="./hale-ui-empty-state.ts"></script>