/**
  ©Copyright 2024 Hale Software Development.  
*/

import {defineComponent } from 'vue';

import haleUiAppBar from "./shared/components/hale-ui-app-bar/hale-ui-app-bar.vue";
import homeComponent from './home/home-component.vue';
import projectsComponent from './projects/projects-component.vue';

export default defineComponent({
  components: {
    haleUiAppBar,
    homeComponent,
    projectsComponent
  }
})