<!-- Copyright 2024 Hale Software Development.  -->
<template>
    <v-app-bar class="app-bar-ui__bar" app>
        <v-app-bar-nav-icon @click="showNavigationDrawer()" class="d-flex d-sm-none">
        </v-app-bar-nav-icon>

        <router-link to="/">
            <v-img 
                    class="mx-2"
                    src="@/assets/images/hale-sd/hale-white.png"
                    width="90"
                    height="90"
                    max-height="90"
                    max-width="90"
                    contain
                />
        </router-link>

        <div id="hale-ui-app-bar-nav" class="app-bar-ui__nav d-none justify-center d-sm-flex">
            <router-link 
                class="app-bar-ui__nav-items" 
                exactActiveClass="app-bar-ui__selected-nav-item" 
                to="/home">{{ $t("HOME") }}
            </router-link>
            <router-link 
                class="app-bar-ui__nav-items" 
                exactActiveClass="app-bar-ui__selected-nav-item" 
                to="/projects">{{ $t("PROJECTS") }}
            </router-link>
            <router-link 
                class="app-bar-ui__nav-items"
                exactActiveClass="app-bar-ui__selected-nav-item" 
                to="/contact">{{ $t("CONTACT_US") }}
            </router-link>
        </div>

        <div>
            <localeSwitcherComponent></localeSwitcherComponent>
        </div>
    </v-app-bar>
    <navbarDrawerComponent ref="navbarDrawer" style="height: 100%"></navbarDrawerComponent>
</template>


<script lang="ts" src="./hale-ui-app-bar.ts"></script>
<style scoped lang="scss" src="./hale-ui-app-bar.scss"></style>