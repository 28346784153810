import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/hale-sd/hale-mini-white.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_empty_state = _resolveComponent("v-empty-state")!

  return (_openBlock(), _createBlock(_component_v_empty_state, {
    text: _ctx.description,
    title: _ctx.title
  }, {
    media: _withCtx(() => [
      _createVNode(_component_v_img, { src: _imports_0 })
    ]),
    _: 1
  }, 8, ["text", "title"]))
}