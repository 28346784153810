import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51f0a8ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-no-wrap justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "portfolio-project-mobile__card mx-auto elevation-1",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRequestDemo(_ctx.projectDetails?.title)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_avatar, {
          class: "ma-3",
          rounded: "0",
          size: "125"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_img, {
              class: "portfolio-project-mobile__project-image",
              "aspect-ratio": "1/1",
              style: {"height":"100%","width":"100%"},
              src: _ctx.getImagePath(_ctx.projectDetails?.image_path)
            }, null, 8, ["src"])
          ]),
          _: 1
        }),
        _createElementVNode("div", null, [
          _createVNode(_component_v_card_title, { class: "pa-2 text-subtitle-1 portfolio-project-mobile__text portfolio-project-mobile__title" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.projectDetails.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "pa-2 text-body-2 portfolio-project-mobile__text portfolio-project-mobile__description" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.projectDetails?.description), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}