<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <v-app>
        <v-layout>
            <haleUiAppBar></haleUiAppBar>  
            <v-main>
                <v-container fluid app class="pa-0">                
                    <router-view v-slot="{ Component }">
                        <transition name="fade">
                            <component :is="Component" />
                        </transition>
                    </router-view>
                </v-container>
            </v-main>

        </v-layout>
    </v-app>
</template>

<script lang="ts" src="./hale-ui.ts"></script>
<style scoped lang="scss" src="./hale-ui.scss"></style>