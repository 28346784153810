/**
  ©Copyright 2024 Hale Software Development.  
*/

import { defineComponent, PropType, ref, toRaw } from 'vue'
import { Project } from '@/api/projects/projects.model'

export default defineComponent({
  name: 'PortfolioProjectComponent',
  props: {
    projectDetails: { type: Object as PropType<Project>, required: true }
  },
  data()  {
    return {}
  },
  methods: {
    /** After on click event, redirect to Contact Us Page */
    handleRequestDemo(projectTitle: string): void {
      this.$router.push({ name: 'contact', query: { title: projectTitle } });
    },
    getImagePath(path: string): string {
      return path ? require("@/assets/images/" + path) : require("@/assets/images/hale-sd/coming-soon.jpg")
    }
  },
})