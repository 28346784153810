/**
  ©Copyright 2024 Hale Software Development.  
*/

import { createRouter, createWebHistory } from 'vue-router'
import HaleView from '../views/hale-ui/hale-ui.vue'
import Projects from '@/views/hale-ui/projects/projects-component.vue'
import Home from '@/views/hale-ui/home/home-component.vue'
import ContactUs from '@/views/hale-ui/contact-us/contact-us-component.vue'
import i8ln from '@/assets/messages/translations'

const routes = [
  {
    path: '/',
    name: 'hale-home',
    redirect: { path: '/home' },
    component: HaleView,
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/projects',
        name: 'projects',
        meta: {
          title: i8ln.global.t(`PROJECTS_HALE_SD`)
        },
        component: Projects
      },
      {
        path: '/contact',
        name: 'contact',
        meta: {
          title: i8ln.global.t(`CONTACT_US_HALE_SD`)
        },
        component: ContactUs,
        props: true
      }
    ],
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
