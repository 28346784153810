<!-- Copyright 2024 Hale Software Development.  -->

<template>
    <div class="contact-us-component__container">
        <span class="contact-us-component__title" v-resize-text="{ratio:1.6, maxFontSize: '80px', minFontSize: '40px'}">
            {{ $t("LETS_WORK_TOGETHER") }}
        </span>
        <br>
        <span class="contact-us-component__subtitle" v-resize-text="{ratio:1.2, maxFontSize: '40px', minFontSize: '20px'}">
            {{ $t("FILL_OUT_FORM") }}
        </span>
        <div class="contact-us-component__form-container">
            <v-row no-gutters v-if="$vuetify.display.mobile && !$vuetify.display.mdAndUp">
                <v-col>
                    <contactFormComponent></contactFormComponent>
                </v-col>
            </v-row>
            <v-row no-gutters v-else>
                <v-col cols="6">
                    <contactFormComponent></contactFormComponent>
                </v-col>
                <v-col cols="6">
                    <v-img 
                        aspect-ratio="4/3"
                        style="height: 60%"
                        src="@/assets/images/hale-sd/contact-us.webp">
                    </v-img>
                </v-col>
            </v-row>

            <footerInformationMobileComponent v-if="$vuetify.display.mobile && !$vuetify.display.mdAndUp"></footerInformationMobileComponent>
            <footerInformationComponent v-else></footerInformationComponent>
        </div>
    </div>
</template>

<script lang="ts" src="./contact-us-component.ts"></script>
<style scoped lang="scss" src="./contact-us-component.scss"></style>