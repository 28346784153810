/**
  © Copyright 2024 Hale Software Development.  
*/

import { defineComponent } from "vue";


export default defineComponent({
    name: 'FooterInformationMobileComponent',
    data() {
        return {}
    }
})