import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    modelValue: _ctx.showDrawer,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDrawer) = $event)),
    absolute: "",
    temporary: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list, {
        nav: "",
        dense: ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              to: item.link,
              key: item.value,
              title: item.title
            }, null, 8, ["to", "title"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}