/**
  © Copyright 2024 Hale Software Development.  
*/

import { defineComponent } from "vue";
import { NotificationType } from "./hale-ui-notification.model";

export default defineComponent({
    name: 'HaleUiNotification',
    data() {
        return {
            timeToClose: 4000,
            showNotification: false,
            notificationType: Number as unknown as NotificationType
        }
    },
    methods: {
        displayNotification(notificationType: NotificationType){
            this.notificationType = notificationType;
            if (notificationType === NotificationType.SUCCESS || 
                notificationType === NotificationType.ERROR) {
                this.showNotification = true;
                setTimeout(() => {
                    this.showNotification = false;
                }, this.timeToClose);
            }
        }
    }
})