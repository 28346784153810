import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../assets/images/hale-sd/software-development.png'
import _imports_1 from '../../../assets/images/hale-sd/integrations-white.png'
import _imports_2 from '../../../assets/images/hale-sd/ux-white.png'


const _withScopeId = n => (_pushScopeId("data-v-7fff54f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "home-component__description-title-section" }
const _hoisted_4 = { class: "home-component__description-title typewriter no-caret" }
const _hoisted_5 = { class: "home-component__description-text" }
const _hoisted_6 = { class: "home-component__deliverable-container" }
const _hoisted_7 = { class: "home-component__deliverable-title" }
const _hoisted_8 = { class: "home-component__deliverable-items" }
const _hoisted_9 = { class: "home-component__deliverable-items" }
const _hoisted_10 = { class: "home-component__deliverable-items" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_homeMobileComponent = _resolveComponent("homeMobileComponent")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _directive_resize_text = _resolveDirective("resize-text")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.$vuetify.display.mobile && !_ctx.$vuetify.display.mdAndUp)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_homeMobileComponent)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_row, { class: "home-component__messages-view" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "6",
                class: "home-component__description-container"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("section", _hoisted_3, [
                    _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_4, [
                      _createTextVNode(_toDisplayString(_ctx.$t("HOME_DESCRIPTION_TITLE")), 1)
                    ])), [
                      [_directive_resize_text, {ratio:1.1, maxFontSize: '60px', minFontSize: '30px'}]
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                cols: "6",
                class: "home-component__description-container"
              }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.$t("HOME_DESCRIPTION_PROBLEMS")), 1)
                  ])), [
                    [_directive_resize_text, {ratio:1.8, maxFontSize: '36px', minFontSize: '20px'}]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_row, { class: "home-component__deliverables-container" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "home-component__deliverables"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("WHAT_WE_DELIVER")), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, [
                  _createVNode(_component_v_row, { "no-gutters": "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_img, {
                            width: 225,
                            "aspect-ratio": "4/3",
                            cover: "",
                            src: _imports_0,
                            class: "home-component__deliverable-image"
                          }),
                          _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_8, [
                            _createTextVNode(_toDisplayString(_ctx.$t("WEB_DEVELOPMENT")), 1)
                          ])), [
                            [_directive_resize_text, {ratio:1.5, maxFontSize: '36px', minFontSize: '20px'}]
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_img, {
                            width: 225,
                            "aspect-ratio": "4/3",
                            cover: "",
                            src: _imports_1,
                            class: "home-component__deliverable-image"
                          }),
                          _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_9, [
                            _createTextVNode(_toDisplayString(_ctx.$t("INTEGRATIONS")), 1)
                          ])), [
                            [_directive_resize_text, {ratio:1.5, maxFontSize: '36px', minFontSize: '20px'}]
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_img, {
                            width: 225,
                            "aspect-ratio": "4/3",
                            cover: "",
                            src: _imports_2,
                            class: "home-component__deliverable-image"
                          }),
                          _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_10, [
                            _createTextVNode(_toDisplayString(_ctx.$t("UI_UX_DESIGNS")), 1)
                          ])), [
                            [_directive_resize_text, {ratio:1.5, maxFontSize: '36px', minFontSize: '20px'}]
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ]))
  ]))
}