/**
  Copyright 2024 Hale Software Development.  
*/

import { defineComponent } from 'vue'
import homeMobileComponent from './components/home-mobile-component/home-mobile-component.vue'

export default defineComponent({
  name: 'HomeComponent',
  components: {
    homeMobileComponent
  },
  data() {
    return {
    
    }
  }
})